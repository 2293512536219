"use client";

import React from "react";
import { useState, useEffect } from "react";
import { setCookie, getCookie } from "cookies-next";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = getCookie("cookieConsent");
    if (!cookieConsent) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieConsent", "accepted", { maxAge: 7 * 24 * 60 * 60 });
    setIsVisible(false);
  };

  const handleReject = () => {
    setCookie("cookieConsent", "rejected", { maxAge: 7 * 24 * 60 * 60 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-12 left-4 w-80 bg-white shadow-lg rounded-lg p-8 z-50 border border-gray-300">
      <p className="text-sm text-gray-800 mb-8 leading-relaxed">
        We use cookies to enhance your browsing experience. By accepting, you
        consent to the use of cookies. You can reject them if you prefer.
      </p>
      <div className="flex justify-end mt-2">
        <button
          onClick={handleReject}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 mr-2 rounded transition-colors"
        >
          Reject
        </button>
        <button
          onClick={handleAccept}
          className="bg-atoll-600 hover:bg-atoll-700 text-white py-2 px-4 rounded transition-colors"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
