"use client";

import { useCallback, useState } from "react";
import { useRouter } from "next/navigation";
import useLoginModal from "@/app/hooks/useLoginModal";
import { SafeReviews, SafeUser } from "@/app/types";
import toast from "react-hot-toast";

interface WriteReviewButtonProps {
  currentUser?: SafeUser | null;
  diveCenterId?: string;
  reviews?: SafeReviews[];
  className?: string;
}

const WriteReviewButton: React.FC<WriteReviewButtonProps> = ({
  currentUser,
  diveCenterId,
  reviews,
  className,
}) => {
  const loginModal = useLoginModal();
  const router = useRouter();

  const checkIfUserReviewedRecently = (userId: string, centerId: string) => {
    const userReviews = reviews?.filter(
      (review) => review.diverId === userId && review.diveCenterId === centerId,
    );

    if (!userReviews || userReviews.length === 0) {
      return false;
    }

    const latestReview = userReviews.reduce((latest, review) => {
      return new Date(review.createdAt) > new Date(latest.createdAt)
        ? review
        : latest;
    });

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    return new Date(latestReview.createdAt) > oneYearAgo;
  };

  const onWriteReview = useCallback(async () => {
    if (!currentUser) {
      loginModal.onOpen();
      return;
    }

    if (!currentUser.diver?.id || currentUser.userType === "Center") {
      toast.error("Invalid user. Centers cannot write reviews.");
      return;
    }

    try {
      if (diveCenterId) {
        const hasReviewed = await checkIfUserReviewedRecently(
          currentUser.diver.id,
          diveCenterId,
        );

        if (hasReviewed) {
          toast.error(
            "You can only write one review per year per dive center.",
          );
          return;
        }

        router.push(`/write-review-center/${diveCenterId}`);
      } else {
        router.push("/write-review");
      }
    } catch (error) {
      console.error("An error occurred while checking your review status.");
    }
  }, [currentUser, diveCenterId, loginModal, router]);

  return (
    <div>
      <button
        className={`px-4 py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-700 cursor-pointer ${className}`}
        onClick={onWriteReview}
      >
        Write a review
      </button>
    </div>
  );
};

export default WriteReviewButton;
