"use client";

import { AiOutlineMenu } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";

import useLoginModal from "@/app/hooks/useLoginModal";
import useRegisterModal from "@/app/hooks/useRegisterModal";
import { SafeUser, SimplifiedUser } from "@/app/types";
import MenuItem from "./MenuItem";

interface UserMenuProps {
  // currentUser?: SafeUser | null;
  currentUser?: SimplifiedUser | null;
  isOpen: boolean;
  toggleOpen: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  currentUser,
  isOpen,
  toggleOpen,
}) => {
  const router = useRouter();
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();

  const handleSignOut = () => {
    signOut({ callbackUrl: "http://localhost:3000/" });
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    toggleOpen();
  };

  const userData = {
    type: currentUser?.userType,
  };

  const createUrlWithParams = (path: string, data: object) =>
    `${path}?userData=${encodeURIComponent(JSON.stringify(data))}`;

  const menuItems = currentUser
    ? [
        ...(currentUser.userType === "Diver"
          ? [
              {
                label: "Edit profile",
                path: `/editprofile/${currentUser.id}`,
                data: userData,
              },
              {
                label: "My reviews",
                path: `/myreviews/${currentUser.id}`,
                data: userData,
              },
              { label: "My favorites", path: "/favorites", data: userData },
              { label: "All job listings", path: "/job-listings" },
              {
                label: "Write a review",
                path: "/write-review",
                style: "bg-atoll-500",
              },
              ...(currentUser.role === "ADMIN"
                ? [
                    { label: "Claim center", path: "/adm/claim-center" },
                    {
                      label: "Centers management",
                      path: "/adm/center-management",
                    },
                    { label: "Approval centers", path: "/adm/approval-center" },
                  ]
                : []),
            ]
          : []),

        ...(currentUser.userType === "Center"
          ? [
              {
                label: "Edit profile",
                path: `/editprofile/${currentUser.id}`,
                data: userData,
              },
              {
                label: "My profile",
                path: `/center/${currentUser.diveCenter?.id}`,
                data: userData,
              },
              { label: "My job listings", path: "/my-job-listings" },
              {
                label: "List your jobs",
                path: "/register-job-listing",
                style: "bg-atoll-500",
              },
            ]
          : []),

        { label: "Logout", onClick: handleSignOut },
      ]
    : [
        { label: "Login", onClick: loginModal.onOpen },
        { label: "Sign up", onClick: registerModal.onOpen },
      ];

  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-3">
        <div
          onClick={toggleOpen}
          className="
            p-2 md:py-2 md:px-3 border-[1px] 
            border-neutral-200 flex flex-row items-center 
            gap-3 rounded-lg cursor-pointer 
            hover:shadow-md transition
          "
        >
          {!currentUser ? (
            <BiUserCircle size={22} />
          ) : (
            <FaUserCircle size={22} />
          )}
          <div className="hidden md:block text-sm">{currentUser?.name}</div>
          <div className="sm:hidden">
            <AiOutlineMenu size={20} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="
          absolute z-50 rounded-xl shadow-md w-[90vw] sm:w-[70vw] md:w-[50vw] lg:w-[30vw] 
          bg-white overflow-hidden right-0 top-12 text-sm
        "
        >
          <div className="flex flex-col cursor-pointer">
            {menuItems.map((item, index) => (
              <div key={index} className={item.style || ""}>
                {item.label === "Logout" && (
                  <hr className="border-t border-black" />
                )}
                <MenuItem
                  label={item.label}
                  onClick={() =>
                    item.onClick
                      ? handleMenuItemClick(item.onClick)
                      : handleMenuItemClick(() =>
                          router.push(
                            createUrlWithParams(item.path, item.data || {}),
                          ),
                        )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
