"use client";

import useLoginModal from "@/app/hooks/useLoginModal";
import { SafeUser } from "@/app/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import CurrencySelector, {
  CurrencyOption,
} from "@/app/components/inputs/CurrencySelector";
import WriteReviewButton from "../ButtonWriteReview";
import axios from "axios";
import toast from "react-hot-toast";
import { useRouter } from "next/navigation";
import { MdCurrencyExchange } from "react-icons/md";
import useCurrencies from "@/app/hooks/useCurrencies";

interface CurrencyProps {
  currentUser?: SafeUser | null;
  isOpen: boolean;
  toggleOpen: () => void;
}

const CurrencyMenu: React.FC<CurrencyProps> = ({
  currentUser,
  isOpen,
  toggleOpen,
}) => {
  const loginModal = useLoginModal();
  const router = useRouter();

  const [userCurrencySelector, setUserCurrencySelector] =
    useState<CurrencyOption | null>(null);
  const { getByCode } = useCurrencies();

  const userCurrency = useMemo(() => {
    return currentUser && currentUser.diver?.currency
      ? getByCode(currentUser.diver.currency)
      : null;
  }, [currentUser, getByCode]);

  useEffect(() => {
    if (userCurrency) {
      setUserCurrencySelector(userCurrency);
    } else {
      setUserCurrencySelector(null);
    }
  }, [userCurrency]);

  const handleOpenCurrencySelector = useCallback(() => {
    if (!currentUser) {
      loginModal.onOpen();
    } else {
      toggleOpen();
    }
  }, [currentUser, loginModal, toggleOpen]);

  const handleClearCurrency = async () => {
    if (currentUser?.diver?.id) {
      try {
        setUserCurrencySelector(null);
        await axios.put(
          `/api/updatediver/updatecurrency/${currentUser.diver.id}`,
          {
            currency: null,
          },
        );
        toast.success("Currency cleared");
        router.refresh();
      } catch (error) {
        toast.error("Error clearing currency.");
      }
    } else {
      toast.error("No currency to clear.");
    }
  };

  const handleCurrencyChange = async (value: CurrencyOption | null) => {
    setUserCurrencySelector(value);

    if (currentUser?.diver?.id && value) {
      try {
        await axios.put(
          `/api/updatediver/updatecurrency/${currentUser.diver.id}`,
          {
            currency: value.code,
          },
        );
        toast.success("Currency updated");
        router.refresh();
      } catch (error) {
        toast.error("Error updating currency.");
      }
    }
  };

  return (
    <>
      <div
        className="flex flex-row p-2 cursor-pointer"
        onClick={handleOpenCurrencySelector}
      >
        {userCurrencySelector ? (
          <span className="text-sm text-gray-700 font-semibold ml-2">
            {userCurrencySelector.symbol === "$" && userCurrency?.symbol === "$"
              ? userCurrencySelector.code
              : userCurrencySelector.symbol}
          </span>
        ) : (
          <MdCurrencyExchange size={20} className="text-gray-500" />
        )}
      </div>

      {isOpen &&
        currentUser &&
        currentUser.diver?.reviews &&
        currentUser.diver?.reviews.length > 0 && (
          <div className="absolute right-24 transform translate-x-10 top-14 bg-white p-2 rounded-md shadow-lg z-50 w-60 max-h-[30vh]">
            <div className="text-sm p-2">Select your currency</div>
            <CurrencySelector
              value={userCurrencySelector}
              onChange={handleCurrencyChange}
            />
            <div className="flex justify-end mt-2">
              <button
                className="px-2 py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-500 cursor-pointer text-sm"
                onClick={handleClearCurrency}
              >
                Clear currency
              </button>
            </div>
          </div>
        )}

      {currentUser &&
        currentUser.diver?.reviews &&
        currentUser.diver?.reviews.length === 0 &&
        isOpen && (
          <div className="absolute right-6 top-14 mx-auto bg-white p-4 rounded-lg shadow-lg z-50 w-80 max-h-[20vh] flex flex-col items-center">
            <div className="text-md font-semibold text-gray-800 mb-2 text-center">
              <span role="img" aria-label="star" className="text-xl">
                ⭐{" "}
              </span>
              Unlock this feature by writing your first review
            </div>
            <WriteReviewButton currentUser={currentUser} />
          </div>
        )}
    </>
  );
};

export default CurrencyMenu;
