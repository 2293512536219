"use client";

import axios from "axios";
import { signIn } from "next-auth/react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaEye, FaEyeSlash } from "react-icons/fa";
import { useCallback, useState, useTransition } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useRouter, useSearchParams } from "next/navigation";

import useLoginModal from "@/app/hooks/useLoginModal";
import useRegisterModal from "@/app/hooks/useRegisterModal";

import Modal from "./Modal";
import Heading from "../Heading";
import Button from "../Button";
import * as z from "zod";
import { RegisterSchemaDiver } from "@/app/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonS } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormError } from "@/app/components/FormError";
import { FormSuccess } from "@/app/components/FormSucess";
import { register } from "@/app/actions/register";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox } from "@/components/ui/checkbox";

const RegisterModal = () => {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | undefined>("");
  const [success, setSuccess] = useState<string | undefined>("");

  const searchParams = useSearchParams();
  const urlError =
    searchParams?.get("error") === "OAuthAccountNotLinked"
      ? "Email already in use with a different provider!"
      : "";

  const form = useForm<z.infer<typeof RegisterSchemaDiver>>({
    resolver: zodResolver(RegisterSchemaDiver),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      agreeToTerms: false,
      recaptchaToken: "",
    },
  });

  const onSubmit = (values: z.infer<typeof RegisterSchemaDiver>) => {
    setError("");
    setSuccess("");

    startTransition(() => {
      register(values).then(async (data) => {
        setError(data.error);
        setSuccess(data.success);

        if (data.error) {
          return;
        }

        try {
          await axios.post("/api/register/diver", { ...values });
          toast.success("Registered! Check your email");
          registerModal.onClose();
          form.reset();
          router.push("/");
        } catch (postError) {
          setError("Error during registration process");
        }
      });
    });
  };

  const onToggle = useCallback(() => {
    registerModal.onClose();
    loginModal.onOpen();
  }, [registerModal, loginModal]);

  const handleRedirect = useCallback(() => {
    registerModal.onClose();
    router.push("/register-my-center");
  }, [registerModal, router]);

  const bodyContent = (
    <div className="flex flex-col gap-2">
      <Heading
        title="Welcome to Scuba Reputation"
        subtitle="Create a DIVE PROFESSIONAL account!"
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }: any) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={isPending}
                      placeholder="Name"
                      type="name"
                    />
                  </FormControl>
                  <FormMessage className="text-red-500 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }: any) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={isPending}
                      placeholder="example@example.com"
                      type="email"
                    />
                  </FormControl>
                  <FormMessage className="text-red-500 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }: any) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <div className="flex w-full items-center">
                    <FormControl>
                      <Input
                        {...field}
                        disabled={isPending}
                        placeholder="******"
                        type={showPassword ? "text" : "password"}
                      />
                    </FormControl>
                    <ButtonS
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="text-neutral-600 border-[1px] rounded-lg border-neutral-200"
                    >
                      {showPassword ? (
                        <FaEyeSlash size={16} />
                      ) : (
                        <FaEye size={16} />
                      )}
                    </ButtonS>
                  </div>
                  <FormMessage className="text-red-500 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="agreeToTerms"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="border-2 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0"
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="text-xs">
                      I have read and agree to the{" "}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        className="text-atoll-600"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="/terms-of-service"
                        target="_blank"
                        className="text-atoll-600"
                      >
                        Terms of Service
                      </a>
                      .
                    </FormLabel>
                    <FormMessage className="text-red-500 text-xs" />
                  </div>
                </FormItem>
              )}
            />
            <div className="w-full">
              <FormField
                control={form.control}
                name="recaptchaToken"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ReCAPTCHA
                        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                        onChange={(value) => field.onChange(value)}
                      />
                    </FormControl>
                    <FormMessage className="text-red-500 text-xs" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <FormError message={error || urlError} />
          <FormSuccess message={success} />
          <ButtonS
            type="submit"
            disabled={isPending}
            className="h-10 disabled:opacity-70 disabled:cursor-not-allowed rounded-lg hover:opacity-80 transition w-full bg-atoll-600 font-semibold text-white text-md py-3"
          >
            Create an account
          </ButtonS>
        </form>
      </Form>
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-2">
      <hr />
      <Button
        outline
        label="Continue with Google"
        icon={FcGoogle}
        onClick={() => signIn("google")}
      />
      <Button
        outline
        label="Continue with Facebook"
        icon={FaFacebook}
        onClick={() => signIn("facebook")}
      />
      <div
        className="
          text-neutral-500 
          text-center 
          font-light 
          text-xs
        "
      >
        <p>
          Already have an account?
          <span
            onClick={onToggle}
            className="
              text-neutral-800
              cursor-pointer 
              hover:underline
            "
          >
            {" "}
            Log in
          </span>
        </p>
        <p>
          Are you a dive center?
          <span
            onClick={handleRedirect}
            className="
              text-neutral-800
              cursor-pointer 
              hover:underline
            "
          >
            {" "}
            Register here
          </span>
        </p>
      </div>
    </div>
  );

  return (
    <Modal
      disabled={isPending}
      isOpen={registerModal.isOpen}
      title="Register as a Dive Pro"
      // actionLabel="Continue"
      onClose={registerModal.onClose}
      // onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default RegisterModal;
