import * as z from "zod";

// export const ReviewSchema = z.object({
//   rating: z
//     .number()
//     .min(1, "Rating must be at least 1")
//     .max(5, "Rating must be at most 5"),
//   comment: z.string().min(1, "Comment must not be empty"),
// });

export const NewPasswordSchema = z
  .object({
    password: z.string().min(6, {
      message: "Minimum 6 characters required",
    }),
    confirmPassword: z.string().min(6, "Confirm password is required"),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        path: ["confirmPassword"],
        message: "Passwords do not match",
        code: z.ZodIssueCode.custom,
      });
    }
  });

export const ResetSchema = z.object({
  email: z.string().email({
    message: "Email is required",
  }),
  recaptchaToken: z.string().min(1, "ReCAPTCHA validation failed"),
});

export const ResetSchemaEmail = z.object({
  email: z.string().email({
    message: "Email is required",
  }),
});

export const LoginSchema = z.object({
  // email: z.string().email({}),
  email: z.string().email({
    message: "Email is required",
  }),
  // password: z.string()
  password: z.string().min(1, {
    message: "Password is required",
  }),
  code: z.optional(z.string()),
});

export const RegisterSchemaDiver = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Minimum 6 characters required"),
  agreeToTerms: z.boolean().refine((val) => val === true, {
    message: "You must agree to the Privacy Policy and Terms of Service",
  }),
  recaptchaToken: z.string().min(1, "ReCAPTCHA validation failed"),
});

export const RegisterSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Email is required"),
  password: z.string().min(6, "Minimum 6 characters required"),
  // agency: z.string().min(1, "Agency is required"),
  agency: z.array(z.string().min(1, "Agency is required")),
  description: z.string().min(1, "Description is required"),
  // photoURL: z.string().url("Invalid URL").optional(),
  photoURL: z.string().optional(),
  latitude: z.string().optional(),
  longitude: z.string().optional(),
  address: z.string().min(1, "Address is required"),
  city: z.string().min(1, "City is required"),
  postalCode: z.string().min(1, "Postal code is required"),
  country: z
    .object({
      label: z.string().min(1, "Country label is required"),
      latlng: z.array(z.number()).min(2, "Latitude and longitude are required"),
      region: z.string().min(1, "Region is required"),
      value: z.string().min(1, "Country value is required"),
    })
    .nullable()
    .refine((data) => data !== null, {
      message: "Country is required",
    }),
  agreeToTerms: z.boolean().refine((val) => val === true, {
    message: "You must agree to the Privacy Policy and Terms of Service",
  }),
  recaptchaToken: z.string().min(1, "ReCAPTCHA validation failed"),
});

export const ContactSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  message: z.string().min(1, "Message is required"),
  recaptchaToken: z.string().min(1, "ReCAPTCHA validation failed"),
});

export const ClaimCenterSchema = z.object({
  centerName: z.string().min(1, "Center name is required"),
  email: z.string().email("Invalid email address"),
  message: z.string().optional(),
  recaptchaToken: z.string().min(1, "ReCAPTCHA validation failed"),
});

export const ListingSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required"),
  position: z.string().min(1, "Position is required"),
  type: z.string().min(1, "Type is required"),
  contact: z.string().email("Invalid email address"),
});
